<template>

  <el-form
    v-if="formModel"
    :rules="formRules"
    :model="formModel"
    ref="formModel"
    label-width="100px"
    label-suffix="："
    @submit.native.stop.prevent="handleFormSubmit('formModel')"
  >
    <el-form-item
      label="店铺名称"
      prop="bsName"
    >
      <el-col :md="9">
        <el-input
          v-model.trim="formModel.bsName"
          type="text"
        ></el-input>
      </el-col>
    </el-form-item>

    <el-form-item
      label="店铺编号"
      prop="store_no"
    >
      <el-col :md="5">
        <!-- <div style="color:#d7d7d7;">
        {{formModel.store_no || '保存后系统将自动生成'}}
        </div> -->
        <el-input
          v-model.trim="formModel.store_no"
          type="text"
        ></el-input>
      </el-col>
    </el-form-item>

    <el-form-item
      label="店铺位置"
      prop="bsAddress"
    >
      <el-col :md="15">
        <el-input
          v-model.trim="formModel.bsAddress"
          type="text"
        ></el-input>
        <el-button
          type="text"
          @click="dialogVisible = true"
        >打开坐标拾点器</el-button>
      </el-col>
    </el-form-item>

    <el-form-item
      label="店铺坐标"
      prop="bsPosition"
    >
      <el-col :md="6">
        <el-input
          v-model.trim="formModel.bsPosition"
          name="bsPosition"
          type="text"
        ></el-input>
      </el-col>
    </el-form-item>

    <el-form-item label="店铺状态">
      <el-radio-group v-model="formModel.bsStatus">
        <el-radio v-for="item in status" :key="item.id" :label="item.id">
          {{item.name}}
        </el-radio>
      </el-radio-group>

    </el-form-item>

    <el-form-item
      label="联系电话"
      prop="bsPhone"
    >
      <el-col :md="6">
        <el-input
          v-model.trim="formModel.bsPhone"
          name="bsPhone"
          type="text"
        ></el-input>
      </el-col>
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        size="medium"
        native-type="submit"
        :loading="submitLoading"
      >{{ submitText }}</el-button>
    </el-form-item>

    <el-dialog
      :visible.sync="dialogVisible"
      min-width="1000"
      width="80%"
    >
      <!-- <el-button type="primary" @click="getIframe">确定</el-button> -->

      <iframe
        id="map"
        src="https://lbs.qq.com/tool/getpoint/getpoint.html"
        width="100%"
        height="400px"
        frameborder="0"
      ></iframe>
    </el-dialog>
  </el-form>
</template>

<script>
// import StoreService from '@admin/services/StoreService'
// import flatry from '@admin/utils/flatry'

export default {
  name: 'StoreForm',
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    store: {
      type: Object
    }
  },
  computed: {
    // getCurrentUserId () {
    //   return this.$store.getters['auth/getCurrentUser'] ? this.$store.getters['auth/getCurrentUser'].id : 0
    // }
  },
  data () {
    return {
      dialogVisible: false,
      submitLoading: false,
      formRules: {
        bsName: [
          {
            required: true,
            message: '请输入店铺名称',
            trigger: 'blur'
          }
        ],
        bsAddress: [
          {
            required: true,
            message: '请输入店铺地址',
            trigger: 'blur'
          }
        ],
        bsPosition: [
          {
            required: true,
            message: '请输入店铺坐标',
            trigger: 'blur'
          }
        ],
        bsPhone: [
          {
            required: true,
            message: '请输入电话号码',
            trigger: 'blur'
          }
        ]
      },
      status: [
        {
          id: 1,
          name: '营业中'
        },
        {
          id: 2,
          name: '休息'
        },
        {
          id: 3,
          name: '停业'
        }
      ],
      formModel: null
    }
  },
  async mounted () {
    this.formModel = Object.assign(this.store)
  },
  methods: {
    handleFormSubmit (formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false
        }
        this.submitLoading = true

        this.$emit(
          'on-submit',
          this.formModel,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    },
    getIframe () {
    }
  }
}
</script>
