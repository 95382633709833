<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <store-form v-if="store"
                :submit-text="pageTitle"
                :store.sync="store"
                :is-edit="true"
                @on-submit="editAdmin">
    </store-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import StoreForm from '@admin/views/store/_EditForm'
import StoreService from '@admin/services/StoreService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'

export default {
  components: { PlaceholderForm, StoreForm },
  data () {
    return {
      pageTitle: '编辑门店信息',
      store: null
    }
  },
  async created () {
    await this.getInfo()
  },
  methods: {
    async getInfo () {
      const { data } = await flatry(StoreService.edit(this.$router.currentRoute.query.id))

      if (data) {
        this.store = data.data
      }
    },
    async editAdmin (store, success, callback) {
      const { data } = await flatry(StoreService.edit(store.id, store))

      if (data) {
        this.$message.success(data.msg)
        this.store = data.data
        success()
      }

      callback()
    }
  }
}
</script>
